<template>
  <div class="modal-container">
    <div class="modal d-block overflow-hidden" style="background: #f8fafb">
      <div class="h-100 w-100 container py-4 px-5 overflow-auto bg-white">
        <div class="w-100 py-4 justify-content-center modal-header border-0">
          <div class="d-flex flex-column align-items-center">
            <h3 class="mb-2">Borang Penilaian {{ this.course.attributes.course_name }}</h3>
            <span class="fw-lighter"
              >Untuk lihat sijil pencapaian anda, sila lengkapkan penilaian
              ini</span
            >
          </div>
        </div>
        <div class="h-100 w-100 container py-4 px-2 bg-white">
          <div class="w-100 row m-0">
            <div class="col-3 px-0">
              <p class="mb-0 fw-bold">Bahagian A</p>
              <p class="fw-light">Berkenaan diri anda</p>
            </div>
            <div class="col-9 ps-5">
              <div
                class="mb-3 row"
                v-for="(sur, indexSur) in sectionA"
                :key="indexSur"
              >
                <label for="staticEmail" class="col-sm-3 col-form-label">{{
                  sur.attributes.question
                }}</label>
                <div class="col-auto">
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-if="sur.attributes.question_type == 'dropdown'"
                    @change="
                      answerChange({
                        question: sur.attributes.question,
                        answer: $event.target.value,
                      })
                    "
                  >
                    <option disabled selected>
                      Pilih {{ sur.attributes.question }}
                    </option>
                    <option
                      v-for="(ans, index) in sur.attributes.answer"
                      :key="index"
                      :value="ans.text"
                    >
                      {{ ans.text }}
                    </option>
                  </select>
                  <div v-else>
                    <div
                      class="form-check form-check-inline me-3"
                      v-for="(ans, index) in sur.attributes.answer"
                      :key="index"
                      v-on:change="
                        answerChange({
                          question: sur.attributes.question,
                          answer: ans.text,
                        })
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="`${indexSur}_sectionA`"
                        :id="`${index}_${ans.text}`"
                        :value="ans.text"
                      />
                      <label
                        class="form-check-label fw-light ms-1"
                        :for="`${index}_${ans.text}`"
                        >{{ ans.text }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 row m-0 border-top mt-3 pt-3">
            <div class="col-3 px-0">
              <p class="mb-0 fw-bold">Bahagian B</p>
              <p class="fw-light">
                Berkenaan pengalaman anda sewaktu kursus dan ujian
              </p>
            </div>
            <div class="col-9 ps-5">
              <div class="mb-3" v-for="(sur, inSur) in sectionB" :key="inSur">
                <div v-if="sur.attributes.question_type == 'radio'">
                  <p for="staticEmail" class="mb-0">
                    {{ `${inSur + 1}.  ${sur.attributes.question}` }}
                  </p>
                  <div
                    class="form-check form-check-inline me-3"
                    v-for="(ans, index) in sur.attributes.answer"
                    :key="index"
                    v-on:change="
                      answerChange({
                        question: sur.attributes.question,
                        answer: ans.text,
                      })
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :name="`${inSur}_sectionB`"
                      :id="`${sur.attributes.question}_${ans.text}`"
                      :value="ans.text"
                    />
                    <label
                      class="form-check-label fw-light ms-1"
                      :for="`${sur.attributes.question}_${ans.text}`"
                      >{{ ans.text }}</label
                    >
                  </div>
                </div>
                <div v-else-if="sur.attributes.question_type == 'sub'">
                  <p for="staticEmail" class="mb-1">
                    {{ `${inSur + 1}. ${sur.attributes.question}` }}
                  </p>
                  <div
                    v-for="(subsque, indexSub) in sur.attributes.subquestion"
                    :key="indexSub"
                    class="d-flex flex-row"
                  >
                    <p for="staticEmail" class="mb-0 col-2">
                      {{ `${subsque.question}` }}
                    </p>
                    <div
                      class="form-check form-check-inline me-3"
                      v-for="(ans, indexSubAns) in subAnswers"
                      :key="`${indexSubAns}`"
                      v-on:change="
                        answerChange({
                          question: `${sur.attributes.question} - ${subsque.question}`,
                          answer: ans,
                        })
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        :name="`${inSur}_${subsque.question}_${sur.id}`"
                        :id="`${inSur}_${subsque.question}_${ans}`"
                        :value="ans"
                      />
                      <label
                        class="form-check-label fw-light ms-1"
                        :for="`${inSur}_${subsque.question}_${ans}`"
                        >{{ ans }}</label
                      >
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p for="staticEmail" class="mb-1">
                    {{ `${inSur + 1}. ${sur.attributes.question}` }}
                  </p>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    @input="
                      answerChange({
                        question: sur.attributes.question,
                        answer: $event.target.value,
                      })
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 m-0 border-top mt-3 d-flex justify-content-end">
            <button class="btn btn-primary btn-sm my-3" @click="submitSurvey">
              <div
                class="spinner-border spinner-border-sm text-light"
                role="status"
                v-if="isLoading"
              ></div>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../utils/API";
import ProgressCircleComp from "./../../components/ProgressCircle.vue";
import ProgressTextComp from "./../../components/ProgressText.vue";
import Swal from "sweetalert2";

export default {
  name: "survey",
  props: ["user", "course"],
  data() {
    return {
      questions: null,
      sectionA: [],
      sectionB: [],
      qna: [],
      isLoading: false,
      subAnswers: ["Lemah", "Sederhana", "Baik", "Cemerlang"],
    };
  },
  methods: {
    answerChange(data) {
      const value = this.qna.find((qa) => qa.question == data.question);

      if (!value) {
        this.qna.push(data);
      } else {
        value.answer = data.answer;
      }
    },
    submitSurvey() {
      let unAnswered = [];
      this.questions
        .filter(
          (q) =>
            q.attributes.question_type != "free" &&
            q.attributes.question_type != "sub"
        )
        .forEach((q) => {
          if (!this.qna.find((qa) => q.attributes.question == qa.question)) {
            unAnswered.push(q);
          }
        });

      console.log(unAnswered);
      if (unAnswered.length) {
        alert("Semua soalan perlu di jawab.");
      } else {
        this.isLoading = true;
        const data = {
          users_permissions_user: this.user.id,
          course: this.course.id,
          survey_question_answer: this.qna,
        };
        console.log(JSON.stringify(data));
        API.post(`answered-surveys`, { data: data }).then(async (res) => {
          const resUser = await API.get(
            `users/${this.user.id}?populate[0]=answered_surveys`
          );
          
          let userAnsweredSurvay = resUser.data.answered_surveys.map(survey => {
            if(typeof survey.data !== 'undefined'){
              return survey.data
            } else {
              return survey
            }
          })
          let answered = res.data.data;
          await API.put(`users/${this.user.id}`, {
            answered_surveys: [...userAnsweredSurvay, answered],
          });
          this.$emit("close");
        });
      }
    },
  },
  async mounted() {
    const resQuestion = (await API.get(`survey-questions?populate=%2A&sort=id`))
      .data;
    this.questions = resQuestion.data;
    this.sectionA = resQuestion.data.filter(
      (ques) => ques.attributes.section == "a"
    );
    this.sectionB = resQuestion.data.filter(
      (ques) => ques.attributes.section == "b"
    );
    console.log(this.sectionA);
    console.log(this.sectionB);
  },
};
</script>