<template>
  <div class="container mt-3">
    <div class="mt-3" v-if="course">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/"><i class="fas fa-home"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/user/courses">Kursus</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ course.attributes.course_name }}
          </li>
        </ol>
      </nav>
    </div>

    <div class="mt-5" v-if="course">
      <div class="mx-2">
        <h3 class="fw-bold">{{ course.attributes.course_name }}</h3>
      </div>
    </div>

    <div class="container-box mt-3">
      <div v-if="topics.length > 0" class="row mx-0 g-2">
        <div
          :class="{
            'col-sm-12 col-md-6': showTestSection(),
            'col-sm-12 col-md-12': !showTestSection(),
          }"
        >
          <div class="header-box position-relative">
            <div class="row mx-0">
              <div class="col-6 col-sm-7 col-md-8">
                <div class="box-left">
                  <h3>Status pembelajaran anda</h3>
                </div>
              </div>
              <div class="col-6 col-sm-5 col-md-4 d-flex justify-content-end">
                <!-- <div class="box-right" v-if="userCourseCompletions.length > 0">
                  <h1>{{ courseProgress() }}%</h1>
                </div> -->
              </div>
            </div>
            <div class="row mx-0 position-absolute bottom-0 w-100 mb-4 pe-4">
              <div class="col-12">
                <div class="d-flex flex-row">
                  <div class="p-1">
                    <div class="card">
                      <div class="card-body text-center">
                        <h2>{{ getTopicsLength("all") }}</h2>
                        <span>Topik</span>
                      </div>
                    </div>
                  </div>
                  <div class="p-1">
                    <div class="card">
                      <div class="card-body text-center">
                        <h2>{{ getTopicsLength("done") }}</h2>
                        <span>Selesai</span>
                      </div>
                    </div>
                  </div>
                  <div class="p-1">
                    <div class="card">
                      <div class="card-body text-center">
                        <h2>{{ getTopicsLength("not_yet_start") }}</h2>
                        <span>Belum mula</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showTestSection()" class="col-sm-12 col-md-6">
          <div
            class="header-box test-bg position-relative"
            :class="{
              'pass-test ':
                highScoreMark &&
                highScoreMark >= course.attributes.pass_mark_percentage,
            }"
          >
            <div class="row mx-0">
              <div
                :class="{
                  'col-6 col-sm-7 col-md-8': highScoreMark,
                  'col-sm-12 col-md-12': !highScoreMark,
                }"
              >
                <div class="box-left">
                  <h3>Ujian Kursus</h3>
                  <p v-if="!highScoreMark">
                    Anda belum mengambil ini lagi. Sila ambil dengan segera.
                  </p>
                  <!-- <p
                    v-else-if="
                      highScoreMark &&
                      highScoreMark < course.attributes.pass_mark_percentage &&
                      !course.attributes.course_name.includes('Ujian Pengukuhan')
                    "
                  >
                    Skor ujian anda kurang daripada nilai pelepasan. Sila ambil
                    semula ujian.
                  </p> -->
                  <p
                    v-else-if="
                      highScoreMark &&
                      highScoreMark >= course.attributes.pass_mark_percentage
                    "
                  >
                    Anda telah menyelesaikan ujian dengan jayanya. Tahniah.
                  </p>
                </div>
              </div>
              <div class="col-6 col-sm-5 col-md-4 d-flex justify-content-end">
                <div
                  class="box-right"
                  v-if="
                    highScoreMark &&
                    highScoreMark < course.attributes.pass_mark_percentage
                  "
                >
                  <h1>{{ highScoreMark }}%</h1>
                </div>
                <div
                  class="box-right no-border"
                  v-if="
                    highScoreMark &&
                    highScoreMark >= course.attributes.pass_mark_percentage
                  "
                >
                  <img src="./../../assets/img/star_badge.png" alt="" />
                </div>
              </div>
            </div>
            <div class="row mx-0 position-absolute bottom-0 w-100 mb-4 pe-4">
              <div class="col-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8">
                <button
                  v-if="!highScoreMark"
                  class="btn btn-primary w-100"
                  @click="selectTest()"
                >
                  Mula Ujian <i class="fas fa-arrow-right"></i>
                </button>
                <!-- <button
                  v-else-if="
                    highScoreMark &&
                    highScoreMark < course.attributes.pass_mark_percentage &&
                    !course.attributes.course_name.includes('Ujian Pengukuhan')
                  "
                  class="btn btn-primary w-100"
                  @click="selectTest()"
                >
                  Ambil Semula <i class="fas fa-arrow-right"></i>
                </button> -->
                <button
                  v-else-if="
                    highScoreMark &&
                    highScoreMark >= course.attributes.pass_mark_percentage
                  "
                  class="btn btn-custom-orange w-100"
                  @click="revealCert()"
                >
                  Lihat Sijil
                  <svg
                    width="16"
                    height="20"
                    viewBox="0 0 16 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.21 13.89L3 23L8 20L13 23L11.79 13.88M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contents mt-3">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 1 }"
              @click="currentActiveNav = 1"
              >Utama</a
            >
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 2 }"
              @click="currentActiveNav = 2"
              >Dalam proses</a
            >
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 3 }"
              @click="currentActiveNav = 3"
              >Selesai</a
            >
          </li>
          <!-- <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ active: currentActiveNav == 4 }"
              @click="currentActiveNav = 4"
              >Notifikasi</a
            >
          </li> -->
        </ul>

        <div v-if="currentActiveNav != 4" class="nav-contents mt-3">
          <div class="row mx-0 g-2">
            <div
              class="col-12"
              v-for="topic in filteredTopics"
              :key="topic.id"
            >
              <div
                class="topic-box position-relative"
                :class="{ 'topic-box-blue' : !hasTopic(topic) }"
                @click="goto(`/user/topics/${topic.id}`, topic)"
              >
                <div class="box-featured-img" :style="topicBoxBg()"></div>
                <div class="topic-box-inner position-relative p-3">
                  <div class="row mx-0">
                    <div class="col-6 col-sm-8 col-md-8">
                      <div class="title">
                        <span
                          v-if="topic.attributes.topic_series"
                          class="d-block mb-1"
                          >Siri {{ topic.attributes.topic_series }}</span
                        >
                        <div class="mt-4">
                          <h5>{{ topic.attributes.topic_name }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-4">
                      <div class="d-flex flex-column justify-content-end align-items-end h-100 position-relative">
                        <!-- TEMP COMMENT -->

                        <!-- <div class="vr-link-badge">
                          <a href="http://vr-content-staging.inference.asia" target="_blank" rel="noopener noreferrer" class="text-muted">
                            <span class="span-click mt-2">Lihat paparan VR <i class="fas fa-cube ms-1"></i></span>
                          </a>
                        </div> -->

                        <!-- TEMP COMMENT -->

                        <progress-text-comp
                          v-if="hasTopic(topic)"
                          :percentage="topicProgress(topic)"
                          type="progress"
                          style="margin-top:33px;"
                        />
                        <span v-else class="span-click"
                          >Klik untuk mula <i class="fas fa-arrow-right"></i
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="filteredTopics.length <= 0" class="nav-contents mt-3">
            <i>Tiada.</i>
          </div>
        </div>
        <div v-else class="nav-contents mt-3">
          <i>Tiada notifikasi untuk setakat ini.</i>
        </div>
      </div>
    </div>
    <survey
      v-if="showSurvey"
      :user="currentUser"
      :course="course"
      @close="closeSurvey"
    />
    <cert-modal
      v-if="showCert"
      :fullName="currentUser ? currentUser.full_name : ''"
      :courseName="course ? course.attributes.course_name : ''"
      @close="showCert = false"
    />
    <loading-spinner v-if="isUpdating" />
  </div>
</template>

<script>
import API from "../../utils/API";
import ProgressCircleComp from "./../../components/ProgressCircle.vue";
import ProgressTextComp from "./../../components/ProgressText.vue";
import CertModal from "@/components/ModalCert";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import Survey from "./Survey.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {
    ProgressCircleComp,
    ProgressTextComp,
    CertModal,
    Survey,
    LoadingSpinner
  },
  data() {
    return {
      course: null,
      topics: [],
      filteredTopics: [],
      userTopicCompletions: [],
      userCourseCompletions: [],
      userCourseMark: [],
      highScoreMark: null,
      currentActiveNav: 1, //1 - Utama, 2- Dalam Progress, 3 - Selesai, 4- Notifikasi,
      showSurvey: false,
      showCert: false,
      answeredSurvey: null,
      isUpdating: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    currentActiveNav(_val) {
      let ids = [];
      switch (_val) {
        case 1: //load all
          this.filteredTopics = this.topics;
          break;
        case 2: //load any progress
          this.userTopicCompletions.forEach((i) => {
            if (i.completion_percentage < 100) ids.push(i.topic.id);
          });
          this.filteredTopics = this.topics.filter((i) => ids.includes(i.id));
          break;
        case 3: //load 100%
          this.userTopicCompletions.forEach((i) => {
            if (i.completion_percentage == 100) ids.push(i.topic.id);
          });
          this.filteredTopics = this.topics.filter((i) => ids.includes(i.id));
          break;
      }
    },
  },
  methods: {
    async goto(_path, _topic) {
      this.isUpdating = true;

      try {
        await this.changeTopicProgress(_topic);
        this.$router.push(_path);
        this.isUpdating = false;
      } catch (error) {
        console.error("An error occurred:", error);
        this.isUpdating = false;
        window.location.reload();
      }
    },
    getTopicsLength(_arg) {
      let ids = [];
      if (_arg == "all") {
        return this.topics.length;
      } else if (_arg == "done") {
        this.userTopicCompletions.forEach((i) => {
          if (i.completion_percentage == 100) ids.push(i.topic.id);
        });
        return this.topics.filter((i) => ids.includes(i.id)).length;
      } else if (_arg == "not_yet_start") {
        this.userTopicCompletions.forEach((i) => ids.push(i.topic.id));
        return (
          this.topics.length -
          this.topics.filter((i) => ids.includes(i.id)).length
        );
      }
    },
    topicBoxBg() {
      let randomNumber = Math.ceil(Math.random() * 4);
      return `
                background-image: url(/img/random/featured-img-${randomNumber}.jpg);
                background-size: cover;
                background-repeat: no-repeat;
                `;
    },
    hasTopic(_topic) {
      if (this.userTopicCompletions.length > 0) {
        let found = this.userTopicCompletions.find(
          (i) => i.topic.id == _topic.id
        );
        if (found) return true;
      }
      return false;
    },
    topicProgress(_topic) {
      if (this.userTopicCompletions.length > 0) {
        let topicProgress = this.userTopicCompletions.find(
          (i) => i.topic.id == _topic.id
        );
        if (topicProgress) return topicProgress.completion_percentage;
      }
      return false;
    },
    courseProgress() {
      if (this.userCourseCompletions.length > 0) {
        let courseProgress = this.userCourseCompletions.find(
          (i) => i.course.id == this.course.id
        );
        if (courseProgress) return courseProgress.completion_percentage;
      }

      return 0;
      // return false;
    },
    changeTopicProgress(_topic) {
      return new Promise(async (resolve, reject) => {
        try{
          let found =
          this.userTopicCompletions.length > 0
            ? this.userTopicCompletions.find((i) => i.topic.id == _topic.id)
            : null;
          if (!found) {
            this.userTopicCompletions.push({
              topic: _topic,
              completion_percentage: 0,
            });
          }

          await API.put(`users/${this.currentUser.id}`, {
            user_topic_completion: this.userTopicCompletions,
          });

          resolve();
        }catch(error){
          reject();
        }
      });
    },
    showTestSection() {
      let completedTopics = 0;

      let itemFound;
      let _userTopicCompletions = this.userTopicCompletions.filter((item) => {
        itemFound = this.topics.find((i) => i.id == item.topic.id);
        if (itemFound) return itemFound;
      });

      _userTopicCompletions.forEach((item) => {
        if (item.completion_percentage == 100) completedTopics++;
      });
      ``;
      if (completedTopics == this.topics.length) return true;
      else return false;
    },
    selectTest() {
      if (!this.validatTakeTest) return false;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Mula Ujian?",
          text: `Ujian ini mempunyai ${this.course.attributes.question_limit} soalan dan anda diberi masa menjawab selama ${this.course.attributes.answer_time_limit? this.course.attributes.answer_time_limit : '40'} minit. Skor yang perlu anda capai bagi meyelesaikan kursus ini secara penuh adalah sebanyak ${this.course.attributes.pass_mark_percentage}%. Sila jawab dengan teliti.`,
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Mula",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$router.push(`/user/courses/${this.course.id}/test`);
          }
        });
    },
    validatTakeTest() {
      if (!this.highScoreMark) return true;
      else if (
        this.highScoreMark &&
        this.highScoreMark <= this.course.attributes.pass_mark_percentage
      )
        return true;
      else return false;
    },
    revealCert() {
      if (this.answeredSurvey == null) {
        this.showSurveyAlert();
      } else {
        this.showCert = true;
      }
    },
    closeSurvey() {
      this.showSurvey = false;
      this.getUserCourseSurvey();
    },
    showSurveyAlert() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Penilaian Kursus",
          text: `Untuk lihat sijil pencapaian anda, sila lengkapkan penilaian kursus ini`,
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Mula",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.showSurvey = true;
          }
        });
    },
    getUserCourseSurvey() {
      return new Promise(async (resolve, reject) => {
        const surverEndpoint = `answered-surveys?filters[users_permissions_user][id][$eq]=${this.currentUser.id}&filters[course][id][$eq]=${this.course.id}`;
        const resSurvey = (await API.get(surverEndpoint)).data;
        if (resSurvey.data.length > 0) {
          // this.answeredSurvey = resSurvey.data[0];

          //as for now (sebab BE kene fix filter query dahulu)
          this.answeredSurvey = resSurvey.data.find(i => i.users_permissions_user && i.users_permissions_user.id == this.currentUser.id)
        }

        resolve(0)
      })
    },
  },
  async mounted() {
    this.isUpdating = true;
    try{
      const res = await API.get(
      `courses/${this.$route.params.courseid}?populate[0]=topics`
      );
      this.course = res.data.data;
      this.topics = res.data.data.attributes.topics.data;
      //sort topics
      this.topics = this.topics.sort((itemA, itemB) => {
        let x = itemA.attributes.topic_series.toLowerCase();
        let y = itemB.attributes.topic_series.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
      this.filteredTopics = this.topics;

      const res02 = await API.get(
        `users/${this.currentUser.id}?populate[user_topic_completion][populate][0]=topic`
      );
      this.userTopicCompletions = res02.data.user_topic_completion;
      //filter only get the topic that is not null.
      this.userTopicCompletions = this.userTopicCompletions.filter(i => i.topic !== null)

      // let itemFound
      // this.userTopicCompletions = this.userTopicCompletions.filter(item => {
      //   itemFound = this.topics.find(i => i.id == item.topic.id)
      //   if(itemFound) return itemFound
      // })

      const res03 = await API.get(
        `users/${this.currentUser.id}?populate[user_course_completions][populate][0]=course`
      );
      this.userCourseCompletions = res03.data.user_course_completions;
      //filter only get the course that is not null.
      this.userCourseCompletions = this.userCourseCompletions.filter(i => i.course !== null)

      const res04 = await API.get(
        `users/${this.currentUser.id}?populate[course_mark][populate][0]=course`
      );
      this.userCourseMark = res04.data.course_mark;
      let marks = this.userCourseMark.filter(
        (i) => i.course.id == this.course.id
      );
      if (marks.length > 0) {
        marks = marks.sort((itemA, itemB) => {
          if (itemA.mark_percentage < itemB.mark_percentage) {
            return 1;
          }
          if (itemA.mark_percentage > itemB.mark_percentage) {
            return -1;
          }
          return 0;
        });
        this.highScoreMark = marks[0].mark_percentage;
      }
      await this.getUserCourseSurvey();
      this.isUpdating = false;
    }catch(error){
      console.error("An error occurred:", error);
      this.isUpdating = false;
      window.location.reload();
    }
    
  },
};
</script>