<template>
  <div id="modal-cert" class="modal d-block">
    <div
      class="modal-dialog modal-fullscreen"
      style="min-height: 400px !important"
    >
      <div class="modal-content">
        <div class="modal-body p-0" :class="{ invisible: isLoading }">
          <button
            type="button"
            class="
              btn-success
              text-light
              btn-sm
              position-absolute
              top-0
              start-0
              ms-2
              mt-2
              px-3
            "
            @click="downloadCert()"
          >
            <i class="fas fa-file-download me-2" />
            Muat Turun
          </button>
          <button
            type="button"
            class="btn btn-sm position-absolute top-0 end-0"
            @click="$emit('close')"
          >
            <i class="fas fa-times fa-2x" />
          </button>
          <div id="downloadable">
            <img
              id="cert-bg"
              class="h-100 w-auto mx-auto d-block"
              src="@/assets/img/TH_cert.jpg"
            />
            <div
              class="content-container"
              :style="{
                width: `${containerWidth}px`,
                left: `${containerLeft}px`,
              }"
            >
              <div
                id="cert-content"
                class="w-100 h-100 d-flex flex-column justify-content-between"
                :style="{
                  paddingTop: `${contentPaddingTop}px`,
                  paddingBottom: `${contentPaddingBottom}px`,
                }"
              >
                <div>
                  <p class="cert-title">Dengan ini diperakukan bahawa</p>
                  <p class="cert-variable">{{ fullName }}</p>
                </div>
                <div>
                  <p class="cert-title">Telah berjaya menamatkan</p>
                  <p class="cert-variable">{{ courseName }}</p>
                </div>
                <div>
                  <p class="cert-title">Di</p>
                  <p class="cert-variable">e-Bimbingan Haji</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-spinner v-if="isLoading || isSaving" />
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  props: ["fullName", "courseName"],
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      containerWidth: 0,
      containerLeft: 0,
      contentPaddingTop: 0,
      contentPaddingBottom: 0,
      contentTitleFontSize: 34,
      contentVariableFontSize: 20,
    };
  },
  methods: {
    downloadCert() {
      this.isSaving = true;

      setTimeout(() => {
        let element = document.getElementById("downloadable");

        html2canvas(element, { scale: 4 }).then((canvas) => {
          canvas.toBlob(async (blob) => {
            await saveAs(blob, `e-Bimbingan ${this.courseName} Cert.png`);

            this.isSaving = false;
          });
        });
      }, 500);
    },
    resize() {
      const modalCert = document.getElementById("modal-cert");

      const bgImage = document.getElementById("cert-bg");

      const contentTitles = document.getElementsByClassName("cert-title");

      const contentVariables = document.getElementsByClassName("cert-variable");

      this.containerWidth = bgImage.clientWidth;

      this.containerLeft = modalCert.clientWidth / 2 - bgImage.clientWidth / 2;

      this.contentPaddingTop = 0.4 * bgImage.clientHeight;

      this.contentPaddingBottom = 0.1 * bgImage.clientHeight;

      this.contentTitleFontSize = 0.05 * bgImage.clientWidth;

      this.contentVariableFontSize = 0.04 * bgImage.clientWidth;

      for (var i = 0; i < contentTitles.length; i++) {
        var ct = contentTitles[i];

        ct.style.setProperty(
          "font-size",
          `${Math.floor(this.contentTitleFontSize)}px`,
          "important"
        );
      }

      for (var i = 0; i < contentVariables.length; i++) {
        var cv = contentVariables[i];

        cv.style.setProperty(
          "font-size",
          `${Math.floor(this.contentVariableFontSize)}px`,
          "important"
        );
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.resize();
    }, 500);

    setTimeout(() => {
      this.isLoading = false;
    }, 700);

    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&family=Tangerine:wght@400&display=swap");

#downloadable {
  width: fit-content;
  height: 100%;
  margin: 0 auto;
}

.content-container {
  position: absolute;
  top: 0;
  height: 100%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.cert-title {
  font-family: "Tangerine", serif;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  color: #000000 !important;
}

.cert-variable {
  font-family: "Roboto-Slab", sans-serif;
  font-weight: 600 !important;
  line-height: 1.1 !important;
  margin-bottom: 0 !important;
  color: #000000 !important;
}
</style>