var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal d-block overflow-hidden",staticStyle:{"background":"#f8fafb"}},[_c('div',{staticClass:"h-100 w-100 container py-4 px-5 overflow-auto bg-white"},[_c('div',{staticClass:"w-100 py-4 justify-content-center modal-header border-0"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('h3',{staticClass:"mb-2"},[_vm._v("Borang Penilaian "+_vm._s(this.course.attributes.course_name))]),_c('span',{staticClass:"fw-lighter"},[_vm._v("Untuk lihat sijil pencapaian anda, sila lengkapkan penilaian ini")])])]),_c('div',{staticClass:"h-100 w-100 container py-4 px-2 bg-white"},[_c('div',{staticClass:"w-100 row m-0"},[_vm._m(0),_c('div',{staticClass:"col-9 ps-5"},_vm._l((_vm.sectionA),function(sur,indexSur){return _c('div',{key:indexSur,staticClass:"mb-3 row"},[_c('label',{staticClass:"col-sm-3 col-form-label",attrs:{"for":"staticEmail"}},[_vm._v(_vm._s(sur.attributes.question))]),_c('div',{staticClass:"col-auto"},[(sur.attributes.question_type == 'dropdown')?_c('select',{staticClass:"form-select",attrs:{"aria-label":"Default select example"},on:{"change":function($event){return _vm.answerChange({
                      question: sur.attributes.question,
                      answer: $event.target.value,
                    })}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(" Pilih "+_vm._s(sur.attributes.question)+" ")]),_vm._l((sur.attributes.answer),function(ans,index){return _c('option',{key:index,domProps:{"value":ans.text}},[_vm._v(" "+_vm._s(ans.text)+" ")])})],2):_c('div',_vm._l((sur.attributes.answer),function(ans,index){return _c('div',{key:index,staticClass:"form-check form-check-inline me-3",on:{"change":function($event){return _vm.answerChange({
                        question: sur.attributes.question,
                        answer: ans.text,
                      })}}},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":(indexSur + "_sectionA"),"id":(index + "_" + (ans.text))},domProps:{"value":ans.text}}),_c('label',{staticClass:"form-check-label fw-light ms-1",attrs:{"for":(index + "_" + (ans.text))}},[_vm._v(_vm._s(ans.text))])])}),0)])])}),0)]),_c('div',{staticClass:"w-100 row m-0 border-top mt-3 pt-3"},[_vm._m(1),_c('div',{staticClass:"col-9 ps-5"},_vm._l((_vm.sectionB),function(sur,inSur){return _c('div',{key:inSur,staticClass:"mb-3"},[(sur.attributes.question_type == 'radio')?_c('div',[_c('p',{staticClass:"mb-0",attrs:{"for":"staticEmail"}},[_vm._v(" "+_vm._s(((inSur + 1) + ". " + (sur.attributes.question)))+" ")]),_vm._l((sur.attributes.answer),function(ans,index){return _c('div',{key:index,staticClass:"form-check form-check-inline me-3",on:{"change":function($event){return _vm.answerChange({
                      question: sur.attributes.question,
                      answer: ans.text,
                    })}}},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":(inSur + "_sectionB"),"id":((sur.attributes.question) + "_" + (ans.text))},domProps:{"value":ans.text}}),_c('label',{staticClass:"form-check-label fw-light ms-1",attrs:{"for":((sur.attributes.question) + "_" + (ans.text))}},[_vm._v(_vm._s(ans.text))])])})],2):(sur.attributes.question_type == 'sub')?_c('div',[_c('p',{staticClass:"mb-1",attrs:{"for":"staticEmail"}},[_vm._v(" "+_vm._s(((inSur + 1) + ". " + (sur.attributes.question)))+" ")]),_vm._l((sur.attributes.subquestion),function(subsque,indexSub){return _c('div',{key:indexSub,staticClass:"d-flex flex-row"},[_c('p',{staticClass:"mb-0 col-2",attrs:{"for":"staticEmail"}},[_vm._v(" "+_vm._s(("" + (subsque.question)))+" ")]),_vm._l((_vm.subAnswers),function(ans,indexSubAns){return _c('div',{key:("" + indexSubAns),staticClass:"form-check form-check-inline me-3",on:{"change":function($event){return _vm.answerChange({
                        question: ((sur.attributes.question) + " - " + (subsque.question)),
                        answer: ans,
                      })}}},[_c('input',{staticClass:"form-check-input",attrs:{"type":"radio","name":(inSur + "_" + (subsque.question) + "_" + (sur.id)),"id":(inSur + "_" + (subsque.question) + "_" + ans)},domProps:{"value":ans}}),_c('label',{staticClass:"form-check-label fw-light ms-1",attrs:{"for":(inSur + "_" + (subsque.question) + "_" + ans)}},[_vm._v(_vm._s(ans))])])})],2)})],2):_c('div',[_c('p',{staticClass:"mb-1",attrs:{"for":"staticEmail"}},[_vm._v(" "+_vm._s(((inSur + 1) + ". " + (sur.attributes.question)))+" ")]),_c('textarea',{staticClass:"form-control",attrs:{"id":"exampleFormControlTextarea1","rows":"5"},on:{"input":function($event){return _vm.answerChange({
                      question: sur.attributes.question,
                      answer: $event.target.value,
                    })}}})])])}),0)]),_c('div',{staticClass:"w-100 m-0 border-top mt-3 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary btn-sm my-3",on:{"click":_vm.submitSurvey}},[(_vm.isLoading)?_c('div',{staticClass:"spinner-border spinner-border-sm text-light",attrs:{"role":"status"}}):_vm._e(),_vm._v(" Submit ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3 px-0"},[_c('p',{staticClass:"mb-0 fw-bold"},[_vm._v("Bahagian A")]),_c('p',{staticClass:"fw-light"},[_vm._v("Berkenaan diri anda")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3 px-0"},[_c('p',{staticClass:"mb-0 fw-bold"},[_vm._v("Bahagian B")]),_c('p',{staticClass:"fw-light"},[_vm._v(" Berkenaan pengalaman anda sewaktu kursus dan ujian ")])])}]

export { render, staticRenderFns }